<template>
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item-sites :abbr="false" :multiple="false" :local="true" v-model="queryForm.channel" :parent="false" @onload="q.channel=queryForm.channel;searchEvent()" :clearable="false"></form-item-sites>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">搜 索</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="exportData">下 载</el-button>
					</form-item>
					<form-item>
						<upload-button action="/adms_api_v0/product/single/import" accept=".csv" title="上传选款搭配" tooltip="csv文件,表头为'website,spu,category,model'"></upload-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item label="商品id:" style="width:200px;">
						<el-input v-model="queryForm.spu" placeholder="请输入" size="mini" clearable></el-input>
					</form-item>
					<form-item-categories v-model="queryForm.category" :site="queryForm.channel"></form-item-categories>
					<form-item-templates v-model="queryForm.template"></form-item-templates>
					<form-item label="选择日期:" style="width:400px;">
						<el-date-picker v-model="queryForm.date" type="datetimerange" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="timestamp" style="width:340px;" size="mini" :clearable="false">
						</el-date-picker>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<div class="d-flex flex-row">
				<el-button type="primary" size="mini" @click="doPreviewAds('config')" class="mr-2">预览广告</el-button>
				<upload-button action="/adms_api_v0/product/images/single/upload" accept=".zip" title="上传UI作图" tooltip="zip文件"></upload-button>
			</div>
			<alert v-if="multipleSelection.length > 0" class="my-2">
				已选择<span class="text-primary d-inline-block mx-1">{{multipleSelection.length}}组搭配</span>
			</alert>
			<single-table ref="single-table" :tableData="tableData" @handleSelectionChange="handleSelectionChange" class="mt-3 w-100"></single-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
			<ads-config-dialog :visible.sync="visible.adsConfig" @callback="(form)=>doPreviewAds('preview', form)" buttonTitle="单图">
			</ads-config-dialog>
			<ads-preview-dialog ref="preview-dialog" :visible.sync="visible.preview" :data="previewData" @callback="()=>doPreviewAds('publish')" title="单图">
			</ads-preview-dialog>
		</el-card>
</template>

<script>
	import Moment from 'moment'
	import Page from '@/common/mixins/page.js'
	import Common from '@/common/mixins/common.js'
	import Dialogs from '@/components/dialog'
	import SingleTable from '@/components/singles/index.vue'
	import $Utils from '@/common/util.js'
	export default {
		mixins: [Page, Common],
		inject: ['adminLayout'],
		components: {
			...Dialogs,
			SingleTable
		},
		data() {
			return {
				queryForm: {
					template: '',
					channel: '',
					spu: '',
					category: '',
					date: [
						Moment().startOf('day').format('x'),
						Moment().endOf('day').format('x')
					]
				},
				q: {
					channel: '',
					createdAtMin: Moment().startOf('day').format('x'),
					createdAtMax: Moment().endOf('day').format('x')
				},
				tableData: [],
				visible: {
					adsConfig: false,
					preview: false
				},
				previewData: {},
				fetchOnCreated: false,
				namespace: 'fb-single-ads'
			}
		},
		methods: {
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.page,
						...this.q
					},
					trigger
				}
				return this.$store.dispatch(`${this.namespace}/getList`, payload)
			},
			handleList(content) {
				this.tableData = content.map(v => {
					let originPic = undefined
					if (v.picManual) {
						originPic = false
					} else if (v.picAuto) {
						originPic = true
					}
					return {
						...v,
						originPic
					}
				})
			},
			// Button Events
			doPreviewAds(type = 'config', data = null) {
				if (this.multipleSelection.length === 0) {
					return this.$showErrMsg('请先选择商品')
				}
				switch (type) {
					case 'config':
						this.visible.adsConfig = true
						break
					case 'preview':
						this.visible.adsConfig = false
						this.adminLayout.showLoading()
						const payload = {
							body: {
								channel: this.q.channel,
								...data,
								productIds: this.multipleSelection.map(v => {
									return {
										id: v.id,
										originPic: v.originPic
									}
								})
							},
							single: true
						}
						this.$store.dispatch(`${this.namespace}/preview`, payload)
							.then(res => {
								const {publishType} = data
								const previewData = {...res}
								if (publishType === 'Normal' || publishType === 'NEW_TEST') {
									previewData['startAt'] = null
									previewData['attribution'] = this.q.channel === 'HKP' ? '1D_CLICK' : '1D_CLICK_OR_VIEW'
								}
								previewData['status'] = 'PAUSED'
								this.previewData = previewData
								this.adminLayout.hideLoading()
								this.visible.preview = true
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
						break
					case 'publish':
						const {startAt} = this.previewData
						if (!!startAt && startAt - new Date().getTime() < 1800*1000) {
							this.$showErrMsg('开始时间需大于当前时间30min+')
							return
						}
						this.adminLayout.showLoading(true)
						this.$store.dispatch(`${this.namespace}/publish`, {id: this.previewData.id, body: this.$refs['preview-dialog'].getOptionsBody()})
							.then(res => {
								this.visible.preview = false
								this.adminLayout.hideLoading()
								this.$refs['single-table'].$refs.multipleTable.clearSelection()
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
					default:
						break
				}
			},
			searchEvent() {
				const {spu, template} = this.queryForm
				const spus = spu.match(/[\w-]+/g) || []
				this.q = {
					createdAtMin: this.queryForm.date[0],
					createdAtMax: this.queryForm.date[1],
					channel: this.queryForm.channel,
					spus,
					category: this.queryForm.category,
					template
				}
				this.page.current = 1
				this.getList()
			},
			exportData() {
				if (JSON.stringify(this.q) === '{}') return
				const createdAtMin = Moment(Number(this.queryForm.date[0])).format('YYYY-MM-DD HH:mm:ss')
				const createdAtMax = Moment(Number(this.queryForm.date[1])).format('YYYY-MM-DD HH:mm:ss')
				this.$store.commit('download-manager/addTask', {
					action: `${this.namespace}/getList`,
					payload: {...this.q},
					title: `Single List(${createdAtMin}~${createdAtMax})`,
					pagable: {page: 1, size: 50},
					dispatch: this.$store.dispatch,
					requestHandler() {
						const params = Array.from(arguments).reduce((lhs, rhs) => {
							return {...lhs, ...rhs}
						}, {})
						return {params}
					},
					responseHandler(res) {
						const content = res.content.map(v => {
							return {
								...v,
								images: (v.images || []).map(k => k.image).join(','),
								mainImages: (v.mainImages || []).join(',')
							}
						})
						return {
							totalPages: res.totalPages,
							content
						}
					}
				})
			}
		}
	}
</script>

