<template>
	<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" @select="pinSelect" :cell-class-name="cellClassName" @cell-click="cellClick">
		<el-table-column type="selection" width="55" :selectable="rowSelectable">
		</el-table-column>
		<el-table-column label="站点" prop="channel" min-width="120">
		</el-table-column>
		<el-table-column label="商品id" prop="spu" min-width="120">
		</el-table-column>
		<el-table-column label="品类" prop="category" min-width="150">
		</el-table-column>
		<el-table-column label="搭配模板" prop="template" min-width="150">
		</el-table-column>
		<el-table-column label="商品主图" min-width="400">
			<template slot-scope="scope">
				<div v-if="scope.row.mainImages" class="table-img-boxs">
					<div style="width:max-content;" class="d-flex flex-row align-items-center h-100">
						<el-image v-for="image in scope.row.mainImages" :src="image" :preview-src-list="scope.row.mainImages"></el-image>
					</div>
				</div>
			</template>
		</el-table-column>
		<el-table-column label="广告图片" min-width="250">
			<template slot-scope="scope">
				<div class="d-flex flex-row">
					<div>
						<img-radio v-if="scope.row.picAuto" :src="scope.row.picAuto"
							:checked="scope.row.originPic===true" @change="()=>changeSelected(scope.row, true)">
						</img-radio>
					</div>
					<div>
						<img-radio v-if="scope.row.picManual" :src="scope.row.picManual"
							:checked="scope.row.originPic===false"
							@change="()=>changeSelected(scope.row, false)"></img-radio>
					</div>
				</div>
			</template>
		</el-table-column>
		<el-table-column label="操作" min-width="80">
			<template slot-scope="scope">
				<update-img-button :data="scope.row" :single="true"></update-img-button>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: []
			}
		},
		methods: {
			changeSelected(row, originPic) {
				row.originPic = originPic
			},
			rowSelectable(row, index) {
				return (row.picAuto || row.picManual) !== null
			},
			cellClick(row, column, cell, event) {
				if (column.property === 'spu' || column.property === 'channel') {
					const selected = this.multipleSelection.find(v => v.id === row.id) !== undefined
					this.$refs['multipleTable'].toggleRowSelection(row, !selected)
				}
			}
		}
	}
</script>

